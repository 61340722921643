import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { TimesIcon } from 'icons/TimesIcon';
import { CheckIcon } from 'icons/internal/CheckIcon';
import { IconButton } from 'components/Button';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Chip, StyledChip } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="compositions/Chip" title="Chip" mdxType="PageTitle" />
    <h2 {...{
      "id": "small-chip"
    }}>{`Small chip`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Rhythm m={2}>\n    <Chip\n      avatar={{ initials: \'P\', color: \'primary\' }}\n      icon={\n        <Rhythm p={1}>\n          <IconButton\n            noHeight\n            aria-label=\"Remove tag\"\n            color=\"neutral\"\n            onClick={() => console.log(\'remove tag\')}\n            shape=\"circle\"\n          >\n            <TimesIcon scale=\"xsmall\" />\n          </IconButton>\n        </Rhythm>\n      }\n      size=\"small\"\n      text=\"Hello world\"\n    />\n    <Chip\n      avatar={{\n        initials: \'P\',\n        color: \'primary\',\n        imgSrc: \'/public/images/avatar.jpg\',\n      }}\n      icon={\n        <Rhythm mr={2}>\n          <CheckIcon scale=\"medium\" />\n        </Rhythm>\n      }\n      size=\"small\"\n      text=\"Hello world\"\n    />\n    <StyledChip\n      actionable\n      avatar={{\n        initials: \'P\',\n      }}\n      avatarBackgroundColor=\"#f41150\"\n      avatarTextColor=\"#fff\"\n      icon={\n        <Rhythm mr={2}>\n          <CheckIcon scale=\"medium\" />\n        </Rhythm>\n      }\n      size=\"small\"\n      tagActivePrimaryColor=\"#da0a44\"\n      tagHoveredPrimaryColor=\"#d64072\"\n      tagInverseColor=\"#fff\"\n      tagPrimaryColor=\"#f41150\"\n      text=\"Hello world\"\n    />\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      CheckIcon,
      IconButton,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Chip,
      StyledChip,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm m={2} mdxType="Rhythm">
      <Chip avatar={{
            initials: 'P',
            color: 'primary'
          }} icon={<Rhythm p={1} mdxType="Rhythm">
            <IconButton noHeight aria-label="Remove tag" color="neutral" onClick={() => console.log('remove tag')} shape="circle" mdxType="IconButton">
              <TimesIcon scale="xsmall" mdxType="TimesIcon" />
            </IconButton>
          </Rhythm>} size="small" text="Hello world" mdxType="Chip" />
      <Chip avatar={{
            initials: 'P',
            color: 'primary',
            imgSrc: '/public/images/avatar.jpg'
          }} icon={<Rhythm mr={2} mdxType="Rhythm">
            <CheckIcon scale="medium" mdxType="CheckIcon" />
          </Rhythm>} size="small" text="Hello world" mdxType="Chip" />
      <StyledChip actionable avatar={{
            initials: 'P'
          }} avatarBackgroundColor="#f41150" avatarTextColor="#fff" icon={<Rhythm mr={2} mdxType="Rhythm">
            <CheckIcon scale="medium" mdxType="CheckIcon" />
          </Rhythm>} size="small" tagActivePrimaryColor="#da0a44" tagHoveredPrimaryColor="#d64072" tagInverseColor="#fff" tagPrimaryColor="#f41150" text="Hello world" mdxType="StyledChip" />
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "medium-chip"
    }}>{`Medium chip`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Rhythm m={2}>\n    <Chip\n      avatar={{ initials: \'P\', color: \'primary\' }}\n      icon={\n        <Rhythm p={1}>\n          <IconButton\n            noHeight\n            aria-label=\"Remove tag\"\n            color=\"neutral\"\n            onClick={() => console.log(\'remove tag\')}\n            shape=\"circle\"\n          >\n            <TimesIcon size={7} />\n          </IconButton>\n        </Rhythm>\n      }\n      size=\"medium\"\n      text=\"Hello world\"\n    />\n    <Chip\n      avatar={{\n        initials: \'P\',\n        color: \'primary\',\n        imgSrc: \'/public/images/avatar.jpg\',\n      }}\n      icon={\n        <Rhythm mr={2}>\n          <CheckIcon scale=\"medium\" />\n        </Rhythm>\n      }\n      size=\"medium\"\n      text=\"Hello world\"\n    />\n    <StyledChip\n      actionable\n      avatar={{\n        initials: \'P\',\n      }}\n      avatarBackgroundColor=\"#f41150\"\n      avatarTextColor=\"#fff\"\n      icon={\n        <Rhythm mr={2}>\n          <CheckIcon scale=\"medium\" />\n        </Rhythm>\n      }\n      size=\"medium\"\n      tagActivePrimaryColor=\"#da0a44\"\n      tagHoveredPrimaryColor=\"#d64072\"\n      tagInverseColor=\"#fff\"\n      tagPrimaryColor=\"#f41150\"\n      text=\"Hello world\"\n    />\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      CheckIcon,
      IconButton,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Chip,
      StyledChip,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm m={2} mdxType="Rhythm">
      <Chip avatar={{
            initials: 'P',
            color: 'primary'
          }} icon={<Rhythm p={1} mdxType="Rhythm">
            <IconButton noHeight aria-label="Remove tag" color="neutral" onClick={() => console.log('remove tag')} shape="circle" mdxType="IconButton">
              <TimesIcon size={7} mdxType="TimesIcon" />
            </IconButton>
          </Rhythm>} size="medium" text="Hello world" mdxType="Chip" />
      <Chip avatar={{
            initials: 'P',
            color: 'primary',
            imgSrc: '/public/images/avatar.jpg'
          }} icon={<Rhythm mr={2} mdxType="Rhythm">
            <CheckIcon scale="medium" mdxType="CheckIcon" />
          </Rhythm>} size="medium" text="Hello world" mdxType="Chip" />
      <StyledChip actionable avatar={{
            initials: 'P'
          }} avatarBackgroundColor="#f41150" avatarTextColor="#fff" icon={<Rhythm mr={2} mdxType="Rhythm">
            <CheckIcon scale="medium" mdxType="CheckIcon" />
          </Rhythm>} size="medium" tagActivePrimaryColor="#da0a44" tagHoveredPrimaryColor="#d64072" tagInverseColor="#fff" tagPrimaryColor="#f41150" text="Hello world" mdxType="StyledChip" />
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "large-chip"
    }}>{`Large chip`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Rhythm m={2}>\n    <Chip\n      avatar={{ initials: \'P\', color: \'primary\' }}\n      icon={\n        <Rhythm p={1}>\n          <IconButton\n            noHeight\n            aria-label=\"Remove tag\"\n            color=\"neutral\"\n            onClick={() => console.log(\'remove tag\')}\n            shape=\"circle\"\n          >\n            <TimesIcon scale=\"xsmall\" />\n          </IconButton>\n        </Rhythm>\n      }\n      size=\"large\"\n      text=\"Hello world\"\n    />\n    <Chip\n      avatar={{\n        initials: \'P\',\n        color: \'primary\',\n        imgSrc: \'/public/images/avatar.jpg\',\n      }}\n      icon={\n        <Rhythm mr={1}>\n          <CheckIcon size={18} />\n        </Rhythm>\n      }\n      size=\"large\"\n      text=\"Hello world\"\n    />\n    <StyledChip\n      actionable\n      avatar={{\n        initials: \'P\',\n      }}\n      avatarBackgroundColor=\"#f41150\"\n      avatarTextColor=\"#fff\"\n      icon={\n        <Rhythm mr={2}>\n          <CheckIcon size={18} />\n        </Rhythm>\n      }\n      size=\"large\"\n      tagActivePrimaryColor=\"#da0a44\"\n      tagHoveredPrimaryColor=\"#d64072\"\n      tagInverseColor=\"#fff\"\n      tagPrimaryColor=\"#f41150\"\n      text=\"Hello world\"\n    />\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      CheckIcon,
      IconButton,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Chip,
      StyledChip,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm m={2} mdxType="Rhythm">
      <Chip avatar={{
            initials: 'P',
            color: 'primary'
          }} icon={<Rhythm p={1} mdxType="Rhythm">
            <IconButton noHeight aria-label="Remove tag" color="neutral" onClick={() => console.log('remove tag')} shape="circle" mdxType="IconButton">
              <TimesIcon scale="xsmall" mdxType="TimesIcon" />
            </IconButton>
          </Rhythm>} size="large" text="Hello world" mdxType="Chip" />
      <Chip avatar={{
            initials: 'P',
            color: 'primary',
            imgSrc: '/public/images/avatar.jpg'
          }} icon={<Rhythm mr={1} mdxType="Rhythm">
            <CheckIcon size={18} mdxType="CheckIcon" />
          </Rhythm>} size="large" text="Hello world" mdxType="Chip" />
      <StyledChip actionable avatar={{
            initials: 'P'
          }} avatarBackgroundColor="#f41150" avatarTextColor="#fff" icon={<Rhythm mr={2} mdxType="Rhythm">
            <CheckIcon size={18} mdxType="CheckIcon" />
          </Rhythm>} size="large" tagActivePrimaryColor="#da0a44" tagHoveredPrimaryColor="#d64072" tagInverseColor="#fff" tagPrimaryColor="#f41150" text="Hello world" mdxType="StyledChip" />
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "chip"
    }}>{`Chip`}</h3>
    <Props of={Chip} mdxType="Props" />
    <h3 {...{
      "id": "styledchip"
    }}>{`StyledChip`}</h3>
    <Props of={StyledChip} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      